import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ReactLenis } from '@studio-freight/react-lenis'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#F2F0ED',
      neutralLight3: '#EDE9E4',
      neutralLight2: '#CCC2B6',
      neutralDark3: '#233446',
      neutralDark2: '#152639',
      neutralDark1: '#000000',
      primaryDark: '#A28B7E',
      primaryLight: '#E3DED9',
      danger: '#F23030',
    },
  },
  fontFamily: {
    heading: "'vincente', serif",
    label: "'inter-variable', sans-serif",
    paragraph: "'inter-variable', sans-serif",
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.neutralDark3};
  font-family: ${theme.fontFamily.heading};
  font-variation-settings: 'wght' 300;
`

const descriptionStyle = css`
  color: ${theme.colors.variants.neutralDark3};
  font-family: ${theme.fontFamily.paragraph};
  font-variation-settings: 'wght' 300;
`

export const styles = {
  label: css`
    color: ${theme.colors.variants.neutralDark3};
    font-family: ${theme.fontFamily.label};
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    line-height: 1rem;
    margin: 0 auto 2.25rem;
    text-transform: uppercase;

    @media (max-width: 1199px) {
      margin-bottom: 0.75rem;
    }
  `,
  title: {
    xl: css`
      ${titleStyle}
      font-size: 6rem;
      line-height: 6rem;
      text-transform: uppercase;

      @media (max-width: 1199px) {
        font-size: 3rem;
        line-height: 3rem;
      }
    `,
    large: css`
      ${titleStyle}
      font-size: 3.75rem;
      line-height: 3.875rem;
      text-transform: uppercase;

      @media (max-width: 1199px) {
        font-size: 2.25rem;
        line-height: 2.25rem;
      }
    `,
    medium: css`
      ${titleStyle}
      font-size: 3rem;
      line-height: 3rem;
      text-transform: uppercase;

      @media (max-width: 1199px) {
        font-size: 1.75rem;
        line-height: 2.1875rem;
      }
    `,
    xm: css`
      ${titleStyle}
      font-size: 2.25rem;
      line-height: 2.25rem;
      text-transform: uppercase;

      @media (max-width: 1199px) {
        font-size: 1.5rem;
        line-height: 1.8125rem;
      }
    `,
    small: css`
      ${titleStyle}
      font-size: 2.25rem;
      line-height: 2.25rem;
      text-transform: uppercase;

      @media (max-width: 1199px) {
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.625rem;
      }
    `,
    xs: css`
      ${titleStyle}
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2rem;

      @media (max-width: 1199px) {
        font-size: 1.125rem;
        line-height: 1.125rem;
      }
    `,
  },
  description: {
    large: css`
      ${descriptionStyle}
      font-size: 1.125rem;
      line-height: 1.625rem;

      @media (max-width: 1199px) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    `,
    small: css`
      ${descriptionStyle}
      font-size: 0.9375rem;
      line-height: 1.5rem;
    `,
  },
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

export type Datepicker = typeof datepicker

export const datepicker = css`
  .react-datepicker {
    min-width: 20rem;
    font-family: ${theme.fontFamily.paragraph};
    background-color: ${theme.colors.variants.neutralDark3};
    color: ${theme.colors.variants.neutralLight5};
  }

  .react-datepicker__portal {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${rgba(theme.colors.variants.neutralLight5, 0.8)};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .react-datepicker__current-month {
    font-family: ${theme.fontFamily.paragraph};
    color: ${theme.colors.variants.primaryLight};
    font-variation-settings: 'wght' 600;
  }

  .react-datepicker__day-name {
    font-family: ${theme.fontFamily.paragraph};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--selected {
    border-color: ${theme.colors.variants.primaryLight};
    background-color: ${theme.colors.variants.primaryLight} !important;
    color: ${theme.colors.variants.neutralLight5} !important;
  }

  .react-datepicker__day:hover {
    background-color: ${theme.colors.variants.primaryDark};
    color: ${theme.colors.variants.neutralLight5};
  }

  .react-datepicker__day:focus {
    background-color: ${theme.colors.variants.primaryDark} !important;
    color: ${theme.colors.variants.neutralLight5} !important;
  }

  .react-datepicker__day--disabled {
    opacity: 0.3;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    input {
      width: 100%;
      height: 3rem;
      background-color: transparent;
      background-image: url('/svg/chevron-down.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 1.3125rem) center;
      border-color: ${theme.colors.variants.neutralLight2};
      color: ${theme.colors.variants.neutralDark2};
      font-family: ${theme.fontFamily.paragraph};
      font-size: 0.875rem;
      font-variation-settings: 'wght' 300;
      line-height: 1.75rem;
      padding: 0 1.5rem;
      &.error {
        border-color: ${theme.colors.variants.danger};
      }

      &::-webkit-input-placeholder {
        color: ${theme.colors.variants.neutralDark2};
        opacity: 0.5;
      }
      &::-moz-placeholder {
        color: ${theme.colors.variants.neutralDark2};
        opacity: 0.5;
      }
      &::-ms-input-placeholder {
        color: ${theme.colors.variants.neutralDark2};
        opacity: 0.5;
      }
      &::placeholder {
        color: ${theme.colors.variants.neutralDark2};
        opacity: 0.5;
      }
    }
  }
`

interface Props {
  children: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({ children }: Props) {
  const isBrowser = typeof window !== 'undefined'

  let smoothScroll = false
  if (isBrowser) smoothScroll = window.innerWidth > 1199 ? true : false

  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {smoothScroll ? (
          <ReactLenis root options={{ duration: 1.4 }}>
            {children}
          </ReactLenis>
        ) : (
          children
        )}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
